import { createSearchMap } from 'common/utils/iterable';
import { useRouter } from 'next/router';
import React from 'react';

import useKurosimNavigation from './navigator';
import { StackNavigation } from './session-navigation';
import { resolveDynamicRoute } from './utils';

export enum PopStateListenerChain {
  Break = 0,
  Continue = 1,
  NoDefault = 2,
}

interface PopStateListener {
  beforePopState(event: PopStateEvent): PopStateListenerChain;
  priority: number;
}

const CustomPopStateListenersContext = React.createContext<
  React.MutableRefObject<Record<string, PopStateListener>>
>({
  current: Object.create(null),
});

export function useRegisterKurosimPopStateListener(
  id: string,
  listener: PopStateListener['beforePopState'],
  priority: number = 0,
) {
  const listeners = React.useContext(CustomPopStateListenersContext);
  React.useEffect(() => {
    listeners.current[id] = {
      beforePopState: listener,
      priority,
    };
    return () => {
      if (Object.prototype.hasOwnProperty.call(listeners.current, id)) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        delete listeners.current[id];
      }
    };
  }, [id, listener, listeners, priority]);
}

export function KurosimNavigatorPopStateHandler(
  props: React.PropsWithChildren,
) {
  const router = useRouter();
  const { back } = useKurosimNavigation();
  const beforePopState = React.useRef<Record<string, PopStateListener>>({});

  // Register pop state event
  React.useEffect(() => {
    /* https://nextjs.org/docs/pages/api-reference/functions/use-router#routerbeforepopstate
    https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event
    There's no guarantee that the user will use the provided back button, instead using swipe gestures or the browser back/forward button to navigate the webapp, which means that we have to intercept those calls somehow.
    Problem is, beforePopState is fired for BOTH back/forward events, so some method of detection is required to figure out if this event is back/forward. */

    // Don't let Next.JS handle navigation
    router.beforePopState(() => false);
    const onPopState = (event: PopStateEvent) => {
      // We execute the listeners starting from the ones with the MOST priority.
      const listeners = Object.values(beforePopState.current).sort(
        (a, b) => b.priority - a.priority,
      );

      let chainState: PopStateListenerChain = PopStateListenerChain.Continue;
      for (const listener of listeners) {
        // If any popstate listener doesn't return
        chainState = listener.beforePopState(event);
        if (
          (chainState & PopStateListenerChain.Continue) ===
          PopStateListenerChain.Continue
        ) {
          break;
        }
      }

      // profile -> payment-method/cards -> payment-method/cards/new (chainState = PopStateListenerChain.NoDefault)
      if (
        (chainState & PopStateListenerChain.NoDefault) ===
        PopStateListenerChain.NoDefault
      ) {
        event.preventDefault();
        return false;
      }

      /* First, we check if this action is a forward action or not.
    Invariant: The Stack Navigation is guaranteed to only contain one instance of each route, which means that if the URL of the future route matches the destination URL, then that future route is the destination. */
      const future = StackNavigation.future();
      if (!future) {
        /* If there is no route in the stack navigation future buffer, then we just assume that this is a 'back' behavior and execute the relevant functions. */
        back();
        return false;
      }

      /* Destination URL will be used for comparison since we don't care if the query is different */
      const [destinationUrl, destinationQuery] = event.state.as?.split('?');
      const futureUrl = resolveDynamicRoute(
        future.pathname as any,
        future.query,
      ); // Note, this might not work properly for ``NavigationRoutes.PublicEsim`` since it doesn't use the dynamic route syntax; it's just a prefix.
      if (destinationUrl !== futureUrl) {
        /* Doesn't match, we assume that this is a 'back' behavior. */
        back();
        return false;
      }

      /* Parse the new query from the URL */
      const query = createSearchMap(
        Array.from(new URLSearchParams(destinationQuery).entries()),
        (entry) => entry,
      ) as Record<string, string>;
      // Remove the entry from the future buffer
      StackNavigation.unpop();
      router.push({
        ...future,
        query: {
          ...future.query,
          ...query,
        },
      });

      return false;
    };
    window.addEventListener('popstate', onPopState);
    return () => {
      router.beforePopState(() => true);
      window.removeEventListener('popstate', onPopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomPopStateListenersContext.Provider value={beforePopState}>
      {props?.children}
    </CustomPopStateListenersContext.Provider>
  );
}
