import { NavigationRoutes } from 'common/routes';
import { useEffectLimits } from 'hooks/use-effect-derivatives';
import { useRouter } from 'next/router';
import React from 'react';

export default function KurosimNavigationPrefetcher() {
  const { prefetch } = useRouter();
  const canRunEffect = useEffectLimits({
    times: 1,
  });
  React.useEffect(() => {
    if (!canRunEffect()) return;
    Promise.all(
      Object.keys(NavigationRoutes).map((key) => {
        return prefetch(NavigationRoutes[key]);
      }),
    );
  }, [canRunEffect, prefetch]);
  return undefined;
}
