import { LanguageType } from 'api-hooks/auth';
import { Expose, Type } from 'class-transformer';
import { UserLevelEnum, UserStatusEnum } from 'common/constants/enum';

// Enum
export enum AvatarProfileTypeEnum {
  Sock1 = 'Sock1',
  Sock2 = 'Sock2',
  Rock = 'Rock',
}

export enum CheckEmailStatusEnum {
  unregistered = 'unregistered',
  active = 'active',
  deleted = 'deleted',
}

// Model
export class AccountSettingsModel {
  @Expose({ name: 'push_notification' })
  pushNotification: boolean;

  @Expose({ name: 'email_notification' })
  emailNotification: boolean;

  @Expose({ name: 'notice_before_payment' })
  noticeBeforePayment: boolean;
}

export class AccountModel {
  id: string;
  email: string;
  status: UserStatusEnum;
  level: UserLevelEnum;

  @Expose({ name: 'first_name' })
  firstName: string;

  @Expose({ name: 'last_name' })
  lastName: string;

  @Expose({ name: 'phone_number' })
  phoneNumber: string;

  @Expose({ name: 'avatar_profile_type' })
  avatarProfileType: AvatarProfileTypeEnum;

  @Type(() => AccountSettingsModel)
  settings: AccountSettingsModel;

  @Expose({ name: 'date_of_birth' })
  @Type(() => Date)
  dateOfBirth: Date;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;
}

export class LinkedDeviceModel {
  id: string;

  @Expose({ name: 'device_name' })
  deviceName: string;

  @Expose({ name: 'last_activity_at' })
  @Type(() => Date)
  lastActivityAt: Date;

  @Expose({ name: 'ip_address' })
  ipAddress: string;

  location: string | null;
}

// Input
export class UpdateProfileMutationInput {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  avatarProfileType: AvatarProfileTypeEnum;
}

export class UpdateSettingsMutationInput {
  pushNotification?: boolean;
  emailNotification?: boolean;
  noticeBeforePayment?: boolean;
  language?: LanguageType;
}
