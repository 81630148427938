import { NotificationData, showNotification } from '@mantine/notifications';
import { CheckCircleIcon, InfoIcon, XCircleIcon } from 'common/assets';
import colors from 'common/styles/colors';

interface Props extends NotificationData {}

const notification = {
  info: (props: Props) =>
    showNotification({
      color: 'blue',
      ...props,
    }),
  success: (props: Props) =>
    showNotification({
      color: colors.statusPositive1,
      icon: <CheckCircleIcon size={50} color={colors.sentimentPositive} />,
      styles: {
        root: { backgroundColor: colors.statusPositive1 },
        description: { color: colors.sentimentPositive },
      },
      ...props,
    }),
  error: (props: Props) => {
    if (!props.message) return;

    return showNotification({
      color: colors.statusNegative1,
      icon: <XCircleIcon size={50} color={colors.sentimentNegative} />,
      styles: {
        root: { backgroundColor: colors.statusNegative1 },
        description: { color: colors.sentimentNegative },
      },
      ...props,
    });
  },
  warn: (props: Props) => {
    if (!props.message) return;

    return showNotification({
      ...props,
      color: colors.statusWarning1,
      icon: <InfoIcon size={50} color={colors.statusWarning7} />,
      styles: {
        root: { backgroundColor: colors.statusWarning1 },
        description: { color: colors.statusWarning7 },
      },
    });
  },
};

export default notification;
