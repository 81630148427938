export enum AffiliateFilterEnum {
  Request = 'request',
  Affiliator = 'affiliator',
  Influencer = 'influencer',
}

export enum AffiliateRequestStatusEnum {
  NoRequest = 'no_request',
  Requested = 'requested',
  Approved = 'approved',
}

export enum AffiliateStatusEnum {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum AffiliateTypeEnum {
  Affiliate = 'affiliate',
  Influencer = 'influencer',
}

export enum AliasMatchTypeEnum {
  Exact = 'exact',
  Contains = 'contains',
}

export enum AliasTypeEnum {
  Bank = 'bank',
  Country = 'country',
  Network = 'network',
  MccMnc = 'mcc_mnc',
}

export enum AvailableLanguageEnum {
  En = 'en',
  Id = 'id',
}

export enum CacheTokenTypeEnum {
  Public = 'public',
  Private = 'private',
}

export enum CampaignStatusEnum {
  Inactive = 'inactive',
  Pending = 'pending',
  Activated = 'activated',
  Deactivated = 'deactivated',
}

export enum ChargeCreditDebitCardFailMessageEnum {
  AuthenticationFailed = 'authentication_failed',
  DeclinedByIssuer = 'declined_by_issuer',
  DeclinedByProcessor = 'declined_by_processor',
  ExpiredCard = 'expired_card',
  IssuerSuspectFraud = 'issuer_suspect_fraud',
  InactiveOrUnauthorizedCard = 'inactive_or_unauthorized_card',
  InsufficientBalance = 'insufficient_balance',
  InvalidCard = 'invalid_card',
  InvalidCvv = 'invalid_cvv',
  IssuerUnavailable = 'issuer_unavailable',
  ProcessorError = 'processor_error',
  StolenCard = 'stolen_card',
  ProcessorTimeout = 'processor_timeout',
  FraudRiskBlocked = 'fraud_risk_blocked',
  InvalidNoRetry = 'invalid_no_retry',
  InvalidSafeRetry = 'invalid_safe_retry',
}

export enum CheckoutPromoTypeEnum {
  Referral = 'referral',
  Voucher = 'voucher',
}

export enum CommandTrackStatusEnum {
  Running = 'running',
  Completed = 'completed',
  Failed = 'failed',
  Skipped = 'skipped',
}

export enum CreditDebitCardBrandEnum {
  Visa = 'VISA',
  Mastercard = 'MASTERCARD',
  Jcb = 'JCB',
}

export enum CreditDebitCardStatusEnum {
  Active = 'active',
  Expired = 'expired',
  Deleted = 'deleted',
}

export enum CreditDebitCardTypeEnum {
  Credit = 'credit',
  Debit = 'debit',
}

export enum CreditSettlementStatusEnum {
  Pending = 'pending',
  Completed = 'completed',
  Canceled = 'canceled',
  Expired = 'expired',
  Rejected = 'rejected',
  Refunded = 'refunded',
}

export enum DataPlanTypeEnum {
  Limited = 'limited',
  Unlimited = 'unlimited',
}

export enum DebtSettlementStatusEnum {
  Pending = 'pending',
  Completed = 'completed',
  Canceled = 'canceled',
  Expired = 'expired',
  Rejected = 'rejected',
  Refunded = 'refunded',
}

export enum DeleteUserReasonEnum {
  SafetyOrPrivacyConcerns = 'safety_or_privacy_concerns',
  TroubleGettingStarted = 'trouble_getting_started',
  IHaveMultipleAccounts = 'i_have_multiple_accounts',
  Others = 'others',
}

export enum DeletedUserStatusEnum {
  Pending = 'pending',
  Recovered = 'recovered',
  Deleted = 'deleted',
}

export enum EmployeeStatusEnum {
  Active = 'active',
  Inactive = 'inactive',
}

export enum EwalletPaymentStatusEnum {
  Pending = 'pending',
  Completed = 'completed',
  Expired = 'expired',
  Canceled = 'canceled',
  Refunded = 'refunded',
}

export enum ExportHistoryStatusEnum {
  Pending = 'pending',
  Completed = 'completed',
  Failed = 'failed',
}

export enum ExportHistoryTypeEnum {
  PricingSchedule = 'pricing_schedule',
  WhitelistStandard = 'whitelist_standard',
  WhitelistPremium = 'whitelist_premium',
  Sales = 'sales',
}

export enum ImportHistoryStatusEnum {
  Ongoing = 'ongoing',
  Completed = 'completed',
  Failed = 'failed',
}

export enum ImportHistoryTypeEnum {
  Sim = 'sim',
  NetworkAccess = 'network_access',
  NetworkList = 'network_list',
  PricingRates = 'pricing_rates',
  NetworkBrands = 'network_brands',
}

export enum NotificationTypeEnum {
  General = 'general',
  PaymentSuccess = 'payment_success',
  PaymentExpired = 'payment_expired',
  PaymentExpiring = 'payment_expiring',
  PaymentRefunded = 'payment_refunded',
  ReferrerBonus = 'referrer_bonus',
  RefereeBonus = 'referee_bonus',
  ReferrerBonusReverted = 'referrer_bonus_reverted',
  RefereeBonusReverted = 'referee_bonus_reverted',
  PartialRefund = 'partial_refund',
  KuroPointsAdjustment = 'kuro_points_adjustment',
  TransferSim = 'transfer_sim',
  RequestTransferSim = 'request_transfer_sim',
  RequestTransferSimAccepted = 'request_transfer_sim_accepted',
  RequestTransferSimRejected = 'request_transfer_sim_rejected',
  AffiliateApproved = 'affiliate_approved',
  AffiliateDeclined = 'affiliate_declined',
  QuotaUsageAlert = 'quota_usage_alert',
  SubscriptionRechargeFail = 'subscription_recharge_fail',
  SubscriptionProductItemNotAvailable = 'subscription_product_item_not_available',
  SubscriptionSimNotRechargeable = 'subscription_sim_not_rechargeable',
  SubscriptionCancel = 'subscription_cancel',
  SimUninstalled = 'sim_uninstalled',
  CreditDebitCardExpired = 'credit_debit_card_expired',
  WithdrawApproved = 'withdraw_approved',
  WithdrawRejected = 'withdraw_rejected',
}

export enum OtpActionEnum {
  VerifyEmail = 'verify-email',
  ChangeEmail = 'change-email',
  ResetPassword = 'reset-password',
  ChangePassword = 'change-password',
}

export enum OtpStatusEnum {
  Active = 'active',
  Expired = 'expired',
  Verified = 'verified',
  Revoked = 'revoked',
}

export enum PartnerCodeEnum {
  Ao = 'AO',
  Ma = 'MA',
  Ta = 'TA',
}

export enum PartnerStatusEnum {
  Active = 'active',
}

export enum PaymentMethodFeeTypeEnum {
  Percentage = 'percentage',
  Value = 'value',
}

export enum PaymentMethodProviderEnum {
  XenditSnap = 'xendit_snap',
  Xendit = 'xendit',
}

export enum PaymentMethodStatusEnum {
  Active = 'active',
  Inactive = 'inactive',
  Maintenance = 'maintenance',
}

export enum PaymentMethodTypeEnum {
  CreditDebitCard = 'credit_debit_card',
  VirtualAccount = 'virtual_account',
  EWallet = 'e_wallet',
  Qris = 'qris',
  RetailOutlet = 'retail_outlet',
}

export enum PaymentStatusEnum {
  Pending = 'pending',
  Completed = 'completed',
  Canceled = 'canceled',
  Expired = 'expired',
  Rejected = 'rejected',
  Refunded = 'refunded',
  Overpayment = 'overpayment',
}

export enum PopularCountryEnum {
  My = 'MY',
  Th = 'TH',
  Sg = 'SG',
  Jp = 'JP',
  Kr = 'KR',
  Cn = 'CN',
  Sa = 'SA',
  Au = 'AU',
  Us = 'US',
  Gb = 'GB',
}

export enum PricingScheduleStatusEnum {
  Drafted = 'drafted',
  Pending = 'pending',
  Active = 'active',
  Inactive = 'inactive',
}

export enum PricingTypeEnum {
  Standard = 'standard',
  Premium = 'premium',
}

export enum ProductAreaCodeEnum {
  Asia = 'ASIA',
  Africa = 'AFRICA',
  Europe = 'EUROPE',
  Nortam = 'NORTAM',
  Latam = 'LATAM',
  Mena = 'MENA',
  Caris = 'CARIS',
  Oceania = 'OCEANIA',
  Sea = 'SEA',
}

export enum ProductItemGradeEnum {
  Standard = 'standard',
  Premium = 'premium',
}

export enum ProductItemTypeEnum {
  Data = 'data',
}

export enum ProductScaleEnum {
  Local = 'local',
  Regional = 'regional',
  Global = 'global',
}

export enum PurchaseInvoiceAddOnTypeEnum {
  Cost = 'cost',
  Discount = 'discount',
}

export enum PurchaseInvoiceStatusEnum {
  Pending = 'pending',
  Completed = 'completed',
  Canceled = 'canceled',
  Refunded = 'refunded',
}

export enum QrisPaymentStatusEnum {
  Pending = 'pending',
  Completed = 'completed',
  Expired = 'expired',
  Canceled = 'canceled',
  Refunded = 'refunded',
}

export enum RedirectTypeEnum {
  InvalidToken = 'invalid_token',
  InvalidSim = 'invalid_sim',
  SimPlanSubscriptionUnavailable = 'sim_plan_subscription_unavailable',
  SimNotSupportProduct = 'sim_not_support_product',
  ProductUnavailable = 'product_unavailable',
}

export enum ReferralTypeEnum {
  Once = 'once',
  Several = 'several',
  None = 'none',
}

export enum SaleInvoicePillStatusEnum {
  All = 'all',
  Unpaid = 'unpaid',
  Completed = 'completed',
  Canceled = 'canceled',
  Expired = 'expired',
  Refunded = 'refunded',
  Rejected = 'rejected',
}

export enum SaleInvoiceStatusEnum {
  Draft = 'draft',
  Invoiced = 'invoiced',
  Completed = 'completed',
  Canceled = 'canceled',
  Expired = 'expired',
  Rejected = 'rejected',
  Refunded = 'refunded',
}

export enum SaleInvoiceTabStatusEnum {
  Ongoing = 'ongoing',
  Completed = 'completed',
  Invalidated = 'invalidated',
}

export enum SaleInvoiceTypeEnum {
  Regular = 'regular',
  Subscription = 'subscription',
}

export enum SaleRefundMethodEnum {
  KuroPoints = 'kuro_points',
  BankTransfer = 'bank_transfer',
}

export enum SaleRefundStatusEnum {
  OnPayment = 'on_payment',
  Paid = 'paid',
}

export enum SimActivityStatusEnum {
  Succeed = 'succeed',
  Failed = 'failed',
}

export enum SimActivityTypeEnum {
  Download = 'download',
  Install = 'install',
  Activate = 'activate',
  Deactivate = 'deactivate',
  Uninstall = 'uninstall',
}

export enum SimApnTypeEnum {
  Automatic = 'automatic',
  Manual = 'manual',
}

export enum SimAreaWhitelistTypeEnum {
  Standard = 'standard',
  Premium = 'premium',
}

export enum SimPlanStatusEnum {
  Pending = 'pending',
  Inactive = 'inactive',
  Active = 'active',
  Deactivated = 'deactivated',
  Deleted = 'deleted',
}

export enum SimSkinEnum {
  Dark = 'dark',
  Green = 'green',
  Blue = 'blue',
  Orange = 'orange',
  KPattern = 'k_pattern',
  BlueAbstract = 'blue_abstract',
}

export enum SimStatusEnum {
  Released = 'released',
  Assigned = 'assigned',
  Installed = 'installed',
  Activated = 'activated',
  Deactivated = 'deactivated',
  Deleted = 'deleted',
  Exchanged = 'exchanged',
  Recycled = 'recycled',
  Disposed = 'disposed',
}

export enum SimTabStatusEnum {
  All = 'all',
  Active = 'active',
  Inactive = 'inactive',
  Archive = 'archive',
}

export enum SimTabTypeEnum {
  Released = 'released',
  OwnedByUser = 'owned_by_user',
  Exchanged = 'exchanged',
  Deleted = 'deleted',
}

export enum SimTransferRequestStatusEnum {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Void = 'void',
}

export enum SnapPaymentStatusEnum {
  Pending = 'pending',
  Completed = 'completed',
  Expired = 'expired',
  Canceled = 'canceled',
  Refunded = 'refunded',
}

export enum SubscriptionHistoryStatusEnum {
  Processing = 'processing',
  Complete = 'complete',
  Reject = 'reject',
}

export enum SubscriptionStatusEnum {
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
  Deleted = 'deleted',
}

export enum UnsubscribeReasonEnum {
  CreditCardChargeFail = 'credit_card_charge_fail',
  ProductItemNotAvailable = 'product_item_not_available',
  CancelByUser = 'cancel_by_user',
  InsufficientKuroPoint = 'insufficient_kuro_point',
  SimTransferred = 'sim_transferred',
  SimRefunded = 'sim_refunded',
  SimExchanged = 'sim_exchanged',
  SimNotRechargeable = 'sim_not_rechargeable',
}

export enum UserAuthProviderEnum {
  None = 'none',
  Google = 'google',
  Apple = 'apple',
}

export enum UserAvatarProfileTypeEnum {
  Rock = 'Rock',
  Sock1 = 'Sock1',
  Sock2 = 'Sock2',
}

export enum UserLevelEnum {
  Personal = 'personal',
  Affiliate = 'affiliate',
  Influencer = 'influencer',
}

export enum UserStatusEnum {
  Active = 'active',
  PendingDeleted = 'pending_deleted',
  Deleted = 'deleted',
}

export enum VaPaymentStatusEnum {
  Pending = 'pending',
  Completed = 'completed',
  Expired = 'expired',
  Canceled = 'canceled',
  Refunded = 'refunded',
}

export enum WalletAdjustmentTypeEnum {
  Other = 'other',
  PartialRefund = 'partial_refund',
}

export enum WalletMutationTabTypeEnum {
  All = 'all',
  Income = 'income',
  Outcome = 'outcome',
}

export enum WalletMutationTypeEnum {
  Payment = 'payment',
  PaymentRefund = 'payment_refund',
  PartialRefund = 'partial_refund',
  RefereeBonus = 'referee_bonus',
  ReferrerBonus = 'referrer_bonus',
  RefereeBonusRefund = 'referee_bonus_refund',
  ReferrerBonusRefund = 'referrer_bonus_refund',
  Adjustment = 'adjustment',
  ReversePayment = 'reverse_payment',
  Withdrawal = 'withdrawal',
}

export enum WalletStatusEnum {
  Active = 'active',
}

export enum WhitelistStatusEnum {
  Draft = 'draft',
  Preview = 'preview',
  Published = 'published',
  Applied = 'applied',
}

export enum WithdrawalStatusEnum {
  Pending = 'pending',
  Rejected = 'rejected',
  Approved = 'approved',
}

export enum WithdrawalTabFilterEnum {
  Request = 'request',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum XenditCardStatusEnum {
  Captured = 'CAPTURED',
  Authorized = 'AUTHORIZED',
  Reversed = 'REVERSED',
  Failed = 'FAILED',
}

export enum XenditEwalletFailureCodeEnum {
  AccountAccessBlocked = 'ACCOUNT_ACCESS_BLOCKED',
  InvalidMerchantCredentials = 'INVALID_MERCHANT_CREDENTIALS',
  UserDeclinedPayment = 'USER_DECLINED_PAYMENT',
  InvalidAccountDetails = 'INVALID_ACCOUNT_DETAILS',
  MaximumLimitReached = 'MAXIMUM_LIMIT_REACHED',
  UserUnreachable = 'USER_UNREACHABLE',
  ChannelUnavailable = 'CHANNEL_UNAVAILABLE',
  InsufficientBalance = 'INSUFFICIENT_BALANCE',
  AccountNotActivated = 'ACCOUNT_NOT_ACTIVATED',
  FailureDetailsUnavailable = 'FAILURE_DETAILS_UNAVAILABLE',
}

export enum XenditEwalletStatusEnum {
  Succeeded = 'SUCCEEDED',
  Pending = 'PENDING',
  Failed = 'FAILED',
  Voided = 'VOIDED',
  Refunded = 'REFUNDED',
}

export enum XenditInvoiceStatusEnum {
  Pending = 'PENDING',
  Paid = 'PAID',
  Settled = 'SETTLED',
  Expired = 'EXPIRED',
}

export enum XenditPaymentMethodEnum {
  IdOvo = 'ID_OVO',
  IdDana = 'ID_DANA',
  IdShopeepay = 'ID_SHOPEEPAY',
  IdLinkaja = 'ID_LINKAJA',
  IdAstrapay = 'ID_ASTRAPAY',
  IdJeniuspay = 'ID_JENIUSPAY',
}

export enum XenditQrisStatusEnum {
  Inactive = 'INACTIVE',
  Active = 'ACTIVE',
  Succeeded = 'SUCCEEDED',
}

export enum XenditVaStatusEnum {
  Pending = 'PENDING',
  Inactive = 'INACTIVE',
  Active = 'ACTIVE',
}

export enum EnumList {
  AffiliateFilter = 'AffiliateFilter',
  AffiliateRequestStatus = 'AffiliateRequestStatus',
  AffiliateStatus = 'AffiliateStatus',
  AffiliateType = 'AffiliateType',
  AliasMatchType = 'AliasMatchType',
  AliasType = 'AliasType',
  AvailableLanguage = 'AvailableLanguage',
  CacheTokenType = 'CacheTokenType',
  CampaignStatus = 'CampaignStatus',
  ChargeCreditDebitCardFailMessage = 'ChargeCreditDebitCardFailMessage',
  CheckoutPromoType = 'CheckoutPromoType',
  CommandTrackStatus = 'CommandTrackStatus',
  CreditDebitCardBrand = 'CreditDebitCardBrand',
  CreditDebitCardStatus = 'CreditDebitCardStatus',
  CreditDebitCardType = 'CreditDebitCardType',
  CreditSettlementStatus = 'CreditSettlementStatus',
  DataPlanType = 'DataPlanType',
  DebtSettlementStatus = 'DebtSettlementStatus',
  DeleteUserReason = 'DeleteUserReason',
  DeletedUserStatus = 'DeletedUserStatus',
  EmployeeStatus = 'EmployeeStatus',
  EwalletPaymentStatus = 'EwalletPaymentStatus',
  ExportHistoryStatus = 'ExportHistoryStatus',
  ExportHistoryType = 'ExportHistoryType',
  ImportHistoryStatus = 'ImportHistoryStatus',
  ImportHistoryType = 'ImportHistoryType',
  NotificationType = 'NotificationType',
  OtpAction = 'OtpAction',
  OtpStatus = 'OtpStatus',
  PartnerCode = 'PartnerCode',
  PartnerStatus = 'PartnerStatus',
  PaymentMethodFeeType = 'PaymentMethodFeeType',
  PaymentMethodProvider = 'PaymentMethodProvider',
  PaymentMethodStatus = 'PaymentMethodStatus',
  PaymentMethodType = 'PaymentMethodType',
  PaymentStatus = 'PaymentStatus',
  PopularCountry = 'PopularCountry',
  PricingScheduleStatus = 'PricingScheduleStatus',
  PricingType = 'PricingType',
  ProductAreaCode = 'ProductAreaCode',
  ProductItemGrade = 'ProductItemGrade',
  ProductItemType = 'ProductItemType',
  ProductScale = 'ProductScale',
  PurchaseInvoiceAddOnType = 'PurchaseInvoiceAddOnType',
  PurchaseInvoiceStatus = 'PurchaseInvoiceStatus',
  QrisPaymentStatus = 'QrisPaymentStatus',
  RedirectType = 'RedirectType',
  ReferralType = 'ReferralType',
  SaleInvoicePillStatus = 'SaleInvoicePillStatus',
  SaleInvoiceStatus = 'SaleInvoiceStatus',
  SaleInvoiceTabStatus = 'SaleInvoiceTabStatus',
  SaleInvoiceType = 'SaleInvoiceType',
  SaleRefundMethod = 'SaleRefundMethod',
  SaleRefundStatus = 'SaleRefundStatus',
  SimActivityStatus = 'SimActivityStatus',
  SimActivityType = 'SimActivityType',
  SimApnType = 'SimApnType',
  SimAreaWhitelistType = 'SimAreaWhitelistType',
  SimPlanStatus = 'SimPlanStatus',
  SimSkin = 'SimSkin',
  SimStatus = 'SimStatus',
  SimTabStatus = 'SimTabStatus',
  SimTabType = 'SimTabType',
  SimTransferRequestStatus = 'SimTransferRequestStatus',
  SnapPaymentStatus = 'SnapPaymentStatus',
  SubscriptionHistoryStatus = 'SubscriptionHistoryStatus',
  SubscriptionStatus = 'SubscriptionStatus',
  UnsubscribeReason = 'UnsubscribeReason',
  UserAuthProvider = 'UserAuthProvider',
  UserAvatarProfileType = 'UserAvatarProfileType',
  UserLevel = 'UserLevel',
  UserStatus = 'UserStatus',
  VaPaymentStatus = 'VaPaymentStatus',
  WalletAdjustmentType = 'WalletAdjustmentType',
  WalletMutationTabType = 'WalletMutationTabType',
  WalletMutationType = 'WalletMutationType',
  WalletStatus = 'WalletStatus',
  WhitelistStatus = 'WhitelistStatus',
  WithdrawalStatus = 'WithdrawalStatus',
  WithdrawalTabFilter = 'WithdrawalTabFilter',
  XenditCardStatus = 'XenditCardStatus',
  XenditEwalletFailureCode = 'XenditEwalletFailureCode',
  XenditEwalletStatus = 'XenditEwalletStatus',
  XenditInvoiceStatus = 'XenditInvoiceStatus',
  XenditPaymentMethod = 'XenditPaymentMethod',
  XenditQrisStatus = 'XenditQrisStatus',
  XenditVaStatus = 'XenditVaStatus',
}
