import { PHONEPREFIX } from 'common/global';
import numeral from 'numeral';
import * as qs from 'qs';

import { toMoneyPrecision } from './number';

export function string2money(rawValue: string | number): string {
  const value = typeof rawValue === 'number' ? rawValue : parseFloat(rawValue);
  if (isNaN(value)) return '';
  // numeral cannot parse exponential values so if your calculation should resolve to zero but ends up as a really small number (which is formatted in exponential notation), numeral will end up formatting it as NaN.
  const numberValue = toMoneyPrecision(value);
  // https://stackoverflow.com/questions/30088192/is-there-a-format-for-numeral-js-that-will-show-decimals-only-when-needed
  return numeral(numberValue).format(`0,0[.]00`);
}

export function string2compactMoney(rawValue: string | number): string {
  const value = typeof rawValue === 'number' ? rawValue : parseFloat(rawValue);
  if (isNaN(value)) return '';
  const numberValue = toMoneyPrecision(value);
  return numeral(numberValue).format(`0,0.[00]a`);
}

export function string2number(value: string | number): string {
  return numeral(`${value}`).format('0,0.[0');
}

export function money2number(value: string): number {
  return numeral(value).value() || 0;
}

export function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export function capitalize(
  value: string,
  options?: {
    /** Default is false */
    firstWordOnly?: boolean;
  },
): string {
  const words = value.split(' ');
  for (let i = 0; i < (options?.firstWordOnly ? 1 : words.length); i++) {
    if (words[i].length === 0) continue;
    words[i] = words[i][0].toUpperCase() + words[i].substring(1);
  }
  return words.join(' ');
}

export const trimPhonePrefix = (phoneNumber: string) =>
  phoneNumber.substring(PHONEPREFIX.length, phoneNumber.length);

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function padStart(
  text: string | number,
  length = 2,
  fill = '0',
): string {
  return text.toString().padStart(length, fill);
}

export function emailMasking(text: string): string {
  if (!text) return '';
  const [mail, domain] = text.split('@');

  if (mail.length <= 5) {
    return `xxx@${domain}`;
  }

  return `xxx${mail.slice(-3)}@${domain}`;
}

export function generateUrlParams(values: any, options?: qs.IStringifyOptions) {
  return qs.stringify(values, options ?? { encode: true });
}

/** Case-insensitive comparison where a LIKE %b% */
export function ilike(a: string, b: string) {
  return a.toLowerCase().includes(b.toLowerCase());
}

// Checks if a string is numeric without using regex. Note that numeric in this case means that it fulfills the following regex: /^[0-9]+$/
export function isNumeric(str: string) {
  for (const chr of str) {
    const ascii = chr.charCodeAt(0);
    const ZERO = '0'.charCodeAt(0);
    const NINE = '9'.charCodeAt(0);
    const valid = ZERO <= ascii && ascii <= NINE;
    if (!valid) {
      return false;
    }
  }
  return true;
}

export function isSupportedVersion(
  minimumVersion: string,
  deviceVersion?: string,
) {
  if (typeof deviceVersion === 'undefined') {
    console.error('deviceVersion is undefined');
    return false;
  }

  try {
    const v1Parts = deviceVersion.split('.').map(Number);
    const v2Parts = minimumVersion.split('.').map(Number);

    for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
      const v1Segment = v1Parts[i] || 0;
      const v2Segment = v2Parts[i] || 0;

      if (v1Segment > v2Segment) return true;
      if (v1Segment < v2Segment) return false;
    }

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}
