import React from 'react';

/**
 * Update this function if the app device info format changes
 * 07/08/2024 Format: KuroSIM/[Version]/[Runtime Version]/[Device Build Number]/[OS Name]/[System Version]/[Brand Name]/[Device Name]
 */
export function getKurosimAppDeviceInfo() {
  if (
    typeof navigator === 'undefined' ||
    !navigator.userAgent.startsWith('KuroSIM')
  ) {
    return undefined;
  }
  const splits = navigator.userAgent.split('/');
  if (splits.length !== 8) {
    console.warn(
      `Detected Kurosim App User Agent with an unexpected format: ${navigator.userAgent}`,
    );
    return undefined;
  }
  const [
    ,
    version,
    runtimeVersion,
    deviceBuildNumber,
    OSName,
    systemVersion,
    brandName,
    deviceName,
  ] = splits;
  return {
    version,
    runtimeVersion,
    deviceBuildNumber,
    OSName,
    systemVersion,
    brandName,
    deviceName,
  };
}

export default function useDetectDevice() {
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  return React.useMemo(() => {
    const isSSR = userAgent === 'SSR';
    const isEdge = () => Boolean(userAgent.match(/Edge|Edg/i));
    const isAndroid = Boolean(userAgent.match(/Android/i));
    const isIos = Boolean(userAgent.match(/iPhone|iPad|iPod|iOS/i));
    const isOpera = Boolean(userAgent.match(/Opera Mini/i));
    const isWindows = Boolean(userAgent.match(/IEMobile/i));
    const isMac =
      Boolean(userAgent.match(/Mac OS X/i)) ||
      Boolean(userAgent.match(/Macintosh/i));

    const isMobile = isAndroid || isIos;
    const isApple = isIos || isMac;
    const isDesktop = !isMobile && !isSSR;
    const isKurosimApp = Boolean(userAgent.match(/^KuroSIM/));
    const isKurosimAndroidApp = Boolean(
      userAgent.match(
        /^KuroSIM\/[^\/]+\/\d+\/[^\/]+\/Android\/[^\/]+\/[^\/]+\/[^\/]+/,
      ),
    );
    const isKurosimIosApp = Boolean(
      userAgent.match(
        /^KuroSIM\/[^\/]+\/\d+\/[^\/]+\/iOS\/[^\/]+\/[^\/]+\/[^\/]+/,
      ),
    );

    const isPWA =
      typeof window === 'undefined'
        ? false
        : window.matchMedia('(display-mode: standalone)').matches;

    return {
      isEdge,
      isSSR,
      isAndroid,
      isIos,
      isOpera,
      isWindows,
      isApple,
      isMac,
      isMobile,
      isDesktop,
      isKurosimApp,
      isKurosimAndroidApp,
      isKurosimIosApp,
      isPWA,
    };
  }, [userAgent]);
}
