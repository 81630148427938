import { PrefixUrl } from 'common/api/prefix-url';
import {
  CustomNavigationRouteParams,
  NavigationRouteParams,
  NavigationRoutes,
} from 'common/routes';
import { getLanguageStorage } from 'hooks/use-language';
import { useRouter } from 'next/router';
import React from 'react';

/** Resolves a dynamic route with named segments into a string */
export function resolveDynamicRoute<
  Route extends keyof CustomNavigationRouteParams,
>(route: Route, params: CustomNavigationRouteParams[Route]['paths']) {
  if (!params) return route;
  return route.replaceAll(/\[([^[\]]+)\]/g, (match, group) => {
    return Object.prototype.hasOwnProperty.call(params, group)
      ? params[group].toString()
      : match;
  });
}

export function replaceExistingUrl(searchParams: Record<string, string>) {
  const url = new URL(window.location.href);
  for (const key of Object.keys(searchParams)) {
    url.searchParams.set(key, searchParams[key]);
  }
  const urlstr = url.toString();
  window.history.replaceState(
    { ...window.history.state, as: urlstr, url: urlstr },
    '',
    urlstr,
  );
}

export function createHistoryEntry<Route extends NavigationRoutes>(
  route: Route,
  props?: NavigationRouteParams[Route],
) {
  return {
    pathname: route as any,
    query: {
      ...(props as any)?.query,
      ...(props as any)?.paths,
    },
  };
}

export function useKurosimSiteUrl() {
  return React.useCallback((url: string) => {
    const langauge = getLanguageStorage();
    const locale = langauge === 'id' ? '/id' : '';
    if (PrefixUrl.isStaging()) {
      // eg. https://staging-site.kurosim.com/id/refund-policy
      return `https://staging-site.kurosim.com${locale}${url}`;
    } else {
      // eg. https://www.kurosim.com/id/refund-policy
      return `https://www.kurosim.com${locale}${url}`;
    }
  }, []);
}

interface UseConsumeRouterQueryProps<
  T extends Record<string, string | string[]>,
> {
  /** Returns a boolean that indicates whether the router params has been consumed or not. If this function returns true, the function is believed to have consumed the params and it will no longer be called again */
  fn(params: T): boolean;
  dependencies: any[];
}

export function useConsumeRouterQuery<
  T extends Record<string, string | string[]>,
>(props: UseConsumeRouterQueryProps<T>) {
  const { fn, dependencies } = props;
  const params = useRouter().query as T;
  const hasConsumedRouterParams = React.useRef(false);
  React.useEffect(() => {
    if (hasConsumedRouterParams.current) {
      return;
    }
    hasConsumedRouterParams.current = fn(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export function backWithoutHistory(
  pathname: NavigationRoutes = NavigationRoutes.Store,
  isNotFound = false,
) {
  if (isNotFound) {
    switch (pathname) {
      //orders
      case NavigationRoutes.PaymentStatus:
      case NavigationRoutes.XenditPayment:
      case NavigationRoutes.OrderDetail:
      case NavigationRoutes.Payment:
      case NavigationRoutes.PaymentSettle:
        return NavigationRoutes.Orders;
      //mysim
      case NavigationRoutes.Esim:
      case NavigationRoutes.EsimInstallGuide:
      case NavigationRoutes.EsimHistory:
      case NavigationRoutes.EsimAllPlans:
      case NavigationRoutes.ArchiveEsim:
      case NavigationRoutes.Recharge:
      case NavigationRoutes.CustomizeDesign:
      case NavigationRoutes.EsimUpcoming:
      case NavigationRoutes.EsimActivation:
      case NavigationRoutes.EsimAddSubscription:
      case NavigationRoutes.EsimViewSubscriptions:
        return NavigationRoutes.MySim;
      //profile
      case NavigationRoutes.Login:
      case NavigationRoutes.WalletMutationHistory:
      case NavigationRoutes.ProfileEdit:
      case NavigationRoutes.ContactUs:
      case NavigationRoutes.AllPaymentCards:
      case NavigationRoutes.AffiliateRegistration:
      case NavigationRoutes.AffiliateAccountSettings:
      case NavigationRoutes.AllSubscriptions:
      case NavigationRoutes.AddToHomeScreen:
        return NavigationRoutes.Profile;
      case NavigationRoutes.ViewSubscription:
      case NavigationRoutes.NewSubscription:
        return NavigationRoutes.AllSubscriptions;
      case NavigationRoutes.ViewPaymentCard:
      case NavigationRoutes.NewPaymentCard:
        return NavigationRoutes.AllPaymentCards;
      //login
      case NavigationRoutes.Register:
      case NavigationRoutes.ForgotPassword:
        // case NavigationRoutes.RegisterAppTracking:
        return NavigationRoutes.Login;
      //profile edit
      case NavigationRoutes.ProfileDelete:
      case NavigationRoutes.ProfileEditEmail:
      case NavigationRoutes.ProfileEditPhone:
      case NavigationRoutes.ProfileEditPassword:
        return NavigationRoutes.ProfileEdit;
      case NavigationRoutes.Devices:
        return NavigationRoutes.Profile;
      // Referral
      case NavigationRoutes.ReferralPerformance:
      case NavigationRoutes.ReferralBind:
        return NavigationRoutes.Referral;
      //home
      case NavigationRoutes.Guides:
      case NavigationRoutes.Notifications:
      case NavigationRoutes.Cart:
      case NavigationRoutes.EsimPublic:
      case NavigationRoutes.Store:
      case NavigationRoutes.MySim:
      case NavigationRoutes.Profile:
      case NavigationRoutes.Search:
      case NavigationRoutes.Orders:
      case NavigationRoutes.OtpVerification:
      case NavigationRoutes.RegisterPushNotifications:
      case NavigationRoutes.ProfileSettingNotification:
      case NavigationRoutes.ResetPassword:
      case NavigationRoutes.Referral:
      case NavigationRoutes.CheckoutCart:
      case NavigationRoutes.Checkout:
      case NavigationRoutes.PlanDetail:
      case NavigationRoutes.EsimPublicInstall:
      case NavigationRoutes.NotFound:
      case NavigationRoutes.Purchase:
      case NavigationRoutes.PurchasePlanDetails:
      case NavigationRoutes.PurchaseAboutEsim:
      default:
        return NavigationRoutes.Store;
    }
  }

  switch (pathname) {
    //orders
    case NavigationRoutes.Purchase:
    case NavigationRoutes.PurchasePlanDetails:
    case NavigationRoutes.PurchaseAboutEsim:
    case NavigationRoutes.PaymentStatus:
    case NavigationRoutes.XenditPayment:
    case NavigationRoutes.OrderDetail:
    case NavigationRoutes.Payment:
    case NavigationRoutes.PaymentSettle:
      return NavigationRoutes.Orders;

    //esim
    case NavigationRoutes.EsimInstallGuide:
    case NavigationRoutes.EsimHistory:
    case NavigationRoutes.EsimAllPlans:
    case NavigationRoutes.CustomizeDesign:
    case NavigationRoutes.EsimUpcoming:
    case NavigationRoutes.EsimActivation:
    case NavigationRoutes.EsimAddSubscription:
    case NavigationRoutes.EsimViewSubscriptions:
    case NavigationRoutes.Recharge:
      return NavigationRoutes.Esim;

    //mysim
    case NavigationRoutes.Esim:
    case NavigationRoutes.ArchiveEsim:
      return NavigationRoutes.MySim;

    //profile
    case NavigationRoutes.Login:
    case NavigationRoutes.WalletMutationHistory:
    case NavigationRoutes.ProfileEdit:
    case NavigationRoutes.ContactUs:
    case NavigationRoutes.AllPaymentCards:
    case NavigationRoutes.AffiliateRegistration:
    case NavigationRoutes.AffiliateAccountSettings:
    case NavigationRoutes.AllSubscriptions:
    case NavigationRoutes.Devices:
    case NavigationRoutes.AddToHomeScreen:
      return NavigationRoutes.Profile;

    // subscription
    case NavigationRoutes.ViewSubscription:
    case NavigationRoutes.NewSubscription:
      return NavigationRoutes.AllSubscriptions;

    // payment cards
    case NavigationRoutes.ViewPaymentCard:
    case NavigationRoutes.NewPaymentCard:
      return NavigationRoutes.AllPaymentCards;

    //login
    case NavigationRoutes.Register:
    case NavigationRoutes.ForgotPassword:
      // case NavigationRoutes.RegisterAppTracking:
      return NavigationRoutes.Login;

    //profile edit
    case NavigationRoutes.ProfileDelete:
    case NavigationRoutes.ProfileEditEmail:
    case NavigationRoutes.ProfileEditPhone:
    case NavigationRoutes.ProfileEditPassword:
      return NavigationRoutes.ProfileEdit;

    // Referral
    case NavigationRoutes.ReferralPerformance:
    case NavigationRoutes.ReferralBind:
      return NavigationRoutes.Referral;

    //home
    case NavigationRoutes.EsimPublicInstall:
    case NavigationRoutes.Guides:
    case NavigationRoutes.Notifications:
    case NavigationRoutes.Cart:
    case NavigationRoutes.EsimPublic:
    case NavigationRoutes.Store:
    case NavigationRoutes.MySim:
    case NavigationRoutes.Profile:
    case NavigationRoutes.Search:
    case NavigationRoutes.Orders:
    case NavigationRoutes.OtpVerification:
    case NavigationRoutes.RegisterPushNotifications:
    case NavigationRoutes.ProfileSettingNotification:
    case NavigationRoutes.ResetPassword:
    case NavigationRoutes.Referral:
    case NavigationRoutes.CheckoutCart:
    case NavigationRoutes.Checkout:
    case NavigationRoutes.PlanDetail:
    case NavigationRoutes.NotFound:
    case NavigationRoutes.__RegisterRoutePrefix:
    default:
      return NavigationRoutes.Store;
  }
}

export function isTabRoute(pathname: string) {
  switch (pathname as NavigationRoutes) {
    case NavigationRoutes.Store:
    case NavigationRoutes.Orders:
    case NavigationRoutes.MySim:
    case NavigationRoutes.Referral:
    case NavigationRoutes.Profile:
      return true;
    default:
      return false;
  }
}
