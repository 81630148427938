import { useUpdateSettings } from 'api-hooks/account';
import { LanguageType } from 'api-hooks/auth';
import { queryClient } from 'common/api/query-client';
import { SessionToken } from 'common/auth';
import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import notification from 'common/helpers/notification';
import useNativeBridge from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { setLocale } from 'yup';

import { useEffectLimits } from './use-effect-derivatives';
import yupEnValidation from '../../locales/en/validation.yup';
import yupIdValidation from '../../locales/id/validation.yup';
import useAuth from '../common/auth/use-auth';

export function getLanguageStorage(): LanguageType | undefined {
  if (typeof window === 'undefined') return undefined;
  const result = localStorage.getItem(
    LocalStorageKeys.Language,
  ) as LanguageType | null;
  return result || undefined;
}

export function setLanguageStorage(value: LanguageType) {
  if (typeof window === 'undefined') return;
  localStorage.setItem(LocalStorageKeys.Language, value);
}

export function selectLanguage(choices: Record<LanguageType, string>) {
  const lang = getLanguageStorage() ?? 'id';
  const choice = choices[lang];
  if (!choice) {
    return Object.values(choices).find((x) => !!x) as string;
  }
  return choice;
}

interface LanguageContextProps {
  language: LanguageType;
  handleLanguage: (value: LanguageType) => void;
}

export const LanguageContext = React.createContext<LanguageContextProps>({
  language: 'id',
  handleLanguage: () => {},
});

export function LanguageProvider({ children }) {
  const [language, setLanguage] = React.useState<LanguageType>('id');
  const { i18n } = useTranslation();
  const { data: me, isFetching: isAuthenticating, isAuthenticated } = useAuth();
  const { mutateAsync: updateSettingAsync } = useUpdateSettings();
  const lang = useRouter().query.lang as LanguageType;

  const send = useNativeBridge({
    handlers: {},
  });

  const meLanguage = me?.data?.settings?.language;

  const handleLanguage = React.useCallback(
    async (value: LanguageType) => {
      const previousStoredLanguage = getLanguageStorage();
      setLanguage(value);
      setLanguageStorage(value);
      i18n.changeLanguage(value);

      if (value === 'en') {
        setLocale(yupEnValidation);
      } else {
        setLocale(yupIdValidation);
      }

      // to native
      send?.({
        data: value,
        type: BridgeMessageType.language,
      });

      // when no authenticated
      // Invalidate all cached data. This is because the data cached in query client uses Accept-Language=<prev lang>, but if we change the language to <new lang>, the data cached in query client will no longer be appropriate as they're in different languages, so the queries have to be invalidated.
      if (typeof SessionToken.get() === 'undefined' && !me) {
        if (previousStoredLanguage === value) return;
        queryClient.invalidateQueries();
        return;
      }

      // when api data and value same
      if (meLanguage === value) {
        return;
      }
      // api fetching
      try {
        await updateSettingAsync({
          language: value,
        });
        // result.message &&
        //   notification.success({
        //     message: result.message,
        //   });
      } catch (e) {
        console.error(e);
        notification.error({
          message: e.message,
        });
      } finally {
        if (previousStoredLanguage === value) return;
        queryClient.invalidateQueries();
      }
    },
    [i18n, me, meLanguage, send, updateSettingAsync],
  );

  const canRunEffect = useEffectLimits({
    times: 1,
    enabled: !isAuthenticating,
    key: me?.data.id,
  });

  React.useEffect(() => {
    if (!canRunEffect()) {
      return;
    }
    const storedLanguage = getLanguageStorage();
    if (isAuthenticated && meLanguage) {
      // User is authenticated, always refer to the stored language preference
      handleLanguage(meLanguage);
    } else {
      // This is the first time the user entered Kurosim, set default language.
      // If lang is provided, also set language.
      handleLanguage(lang ?? storedLanguage ?? 'id');
    }
  }, [
    handleLanguage,
    isAuthenticated,
    isAuthenticating,
    lang,
    canRunEffect,
    meLanguage,
  ]);

  return (
    <LanguageContext.Provider value={{ language, handleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export default function useLanguage() {
  const context = React.useContext(LanguageContext);
  return context;
}
