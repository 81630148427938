import Config from 'common/config';
import { getCookie, setCookie } from 'cookies-next';

export const API_PREFIX = '/api/user';
export class PrefixUrl {
  static readonly key = 'prefix-url';
  static readonly defaultPrefix = Config.apiEndpoint + API_PREFIX;
  static get() {
    const url = getCookie(PrefixUrl.key);
    return url || PrefixUrl.defaultPrefix;
  }
  static set(url = PrefixUrl.defaultPrefix) {
    setCookie(PrefixUrl.key, url || PrefixUrl.defaultPrefix);
  }
  static isStaging() {
    return Config.apiEndpoint.includes('staging');
  }
}
