import { PrefixUrl } from 'common/api/prefix-url';
import useDetectDevice from 'hooks/use-detect-device';
import { useIsSmallScreen } from 'hooks/use-is-small-screen';

export default function PrefixUrlModifier() {
  const { isDesktop } = useDetectDevice();
  const isMobile = useIsSmallScreen();
  if (!PrefixUrl.isStaging()) return null;
  if (isMobile) return null;
  if (isDesktop) {
    return (
      <div
        style={{
          position: 'fixed',
          bottom: 8,
          right: 8,
          opacity: 0.2,
          cursor: 'pointer',
          zIndex: 3,
        }}
        onClick={() => {
          const url = prompt(
            'Staging Url (eg. https://staging-api.kurosim.com/api/user):',
            PrefixUrl.get(),
          );
          if (url) {
            PrefixUrl.set(url);
            window.location.reload();
          }
        }}
      >
        staging mode
      </div>
    );
  }
}
