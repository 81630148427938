import notification from 'common/helpers/notification';
import React from 'react';
interface UseEffectLimitsConstraints {
  times?: number;
  millis?: number;
}

interface UseEffectLimitsProps extends UseEffectLimitsConstraints {
  key?: string; // To reset times and millis if required
  enabled?: boolean;
  condition?(props: UseEffectLimitsConstraints): boolean;
}

export function useEffectLimits(props: UseEffectLimitsProps) {
  const {
    times: maxTimes,
    millis: maxMillis,
    condition,
    key,
    enabled = true,
  } = props;
  const times = React.useRef(0);
  const startTime = React.useRef(Date.now());

  React.useEffect(() => {
    times.current = 0;
    startTime.current = Date.now();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  /** The value returned by this function determines whether the code after is allowed to continue or not. */
  return React.useCallback(() => {
    const millis = Date.now() - startTime.current;
    let verdict = true;
    if (!enabled) {
      verdict = false;
    }
    if (maxTimes != null && times.current >= maxTimes) {
      verdict = false;
    }
    if (maxMillis != null) {
      if (millis > maxMillis) {
        verdict = false;
      }
    }
    if (condition != null) {
      const conditionVerdict = condition({
        millis,
        times: times.current,
      });
      verdict = verdict && conditionVerdict;
    }

    if (verdict) {
      times.current += 1;
    }

    return verdict;
  }, [condition, enabled, maxMillis, maxTimes]);
}

interface UseStateIfMountedProps<T> {
  /** Initial value of the useState hook. This is optional */
  initialValue?: T | undefined;
  /** The function to get the actual initial value value */
  initializer(): Promise<T> | T;
}
export function useStateIfMounted<T>(
  props: UseStateIfMountedProps<T>,
): [T | undefined, React.Dispatch<React.SetStateAction<T>>] {
  const { initializer } = props;
  const [state, setState] = React.useState(props.initialValue);
  const canRunEffect = useEffectLimits({
    times: 1,
  });
  React.useEffect(() => {
    if (!canRunEffect()) {
      return;
    }
    (async function () {
      setState(await initializer());
    })();
  }, [canRunEffect, initializer]);

  return [state, setState];
}

interface UseAsyncFunctionLoadingStateReturn<
  T extends (...args: any) => Promise<any>,
> {
  loading: boolean;
  error: any;
  fn: T;
}

interface UseAsyncFunctionLoadingStateOptions {
  logError?: boolean;
}
export function useAsyncFunctionLoadingState<T extends (...args: any) => any>(
  fn: T,
  options?: UseAsyncFunctionLoadingStateOptions,
): UseAsyncFunctionLoadingStateReturn<T> {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>(null);

  React.useEffect(() => {
    if (options?.logError && error) {
      notification.error({ message: error });
    }
  }, [error, options?.logError]);

  return {
    loading,
    error,
    fn: (async (...args) => {
      setLoading(true);
      try {
        const result = await fn(...args);
        setLoading(false);
        return result;
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    }) as T,
  };
}
