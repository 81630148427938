import { NavigationRoutes } from 'common/routes';
import useNativeBridge from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import { useRouter } from 'next/router';
import React from 'react';

export default function KurosimNavigationBridgeController() {
  const router = useRouter();
  const { replace } = useKurosimNavigation();

  const send = useNativeBridge({
    handlers: {
      // deeplink
      [BridgeMessageType.KurosimWebNavigation]: (message, context) => {
        const path = decodeURIComponent(message.data);
        router.replace(path);
      },

      // tab navigation
      [BridgeMessageType.KurosimTabNavigation]: (message, context) => {
        const path = message.data;
        switch (path) {
          case 'home':
            replace(NavigationRoutes.Store);
            break;
          case 'orders':
            replace(NavigationRoutes.Orders as any);
            break;
          case 'my-sim':
            replace(NavigationRoutes.MySim);
            break;
          case 'referral':
            replace(NavigationRoutes.Referral);
            break;
          case 'profile':
            replace(NavigationRoutes.Profile);
            break;
        }
      },
    },
  });

  // set bridge is ready
  React.useEffect(() => {
    send?.({
      data: null,
      type: BridgeMessageType.KurosimWebLoaded,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // control the bottom navigation native
  React.useEffect(() => {
    switch (router.pathname as NavigationRoutes) {
      case NavigationRoutes.Store:
        send?.({
          type: BridgeMessageType.KurosimTabNavigationLoaded,
          data: 'home',
        });
        break;
      case NavigationRoutes.Orders:
        send?.({
          type: BridgeMessageType.KurosimTabNavigationLoaded,
          data: 'orders',
        });
        break;
      case NavigationRoutes.MySim:
        send?.({
          type: BridgeMessageType.KurosimTabNavigationLoaded,
          data: 'my-sim',
        });
        break;
      case NavigationRoutes.Referral:
        send?.({
          type: BridgeMessageType.KurosimTabNavigationLoaded,
          data: 'referral',
        });
        break;
      case NavigationRoutes.Profile:
        send?.({
          type: BridgeMessageType.KurosimTabNavigationLoaded,
          data: 'profile',
        });
        break;
      default:
        send?.({
          type: BridgeMessageType.KurosimTabNavigationLoaded,
          data: null,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  return null;
}
